<template>
    <nav id="breadcrumbs">
        <ul>
            <li v-for="(item, indx) of breadcrumb" :key="indx"><a href="javascript:void(0)"> {{ item }} </a></li>
        </ul>
    </nav>
</template>

<script>
export default {
    data(){
        return {
            breadcrumb: []
        }
    },
    methods: {
        generateBreadcrumb(){
            let path = this.$route.path,
                segments = path.split('/')
                this.breadcrumb = []
                segments.forEach(element => {
                    if(element){
                        this.breadcrumb.push(this.$options.filters.capitalize(element))
                    }
                });
        }
    },
    created: function(){
        this.generateBreadcrumb()
    },
    watch:{
        "$route.path"(){
            this.generateBreadcrumb()
        }
    },
    filters: {
        capitalize: function (value) {
            if (!value) return ''
            value = value.toString();
            let strArry = value.split('-')
            if(strArry.length > 1){
                return strArry[0].charAt(0).toUpperCase() + strArry[0].slice(1) +' '+ strArry[1].charAt(0).toUpperCase() + strArry[1].slice(1)
            }
            else 
                return value.charAt(0).toUpperCase() + value.slice(1)
        }
    } 
}
</script>